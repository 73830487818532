import React from "react";
import "react-vis/dist/style";
import { createGlobalStyle } from "styled-components";
import { fontColor, grayColor } from "../../globals/colors";
import { isMobile } from "../../services/device";
const mobile = isMobile();
const GlobalStyle = createGlobalStyle`
   html,body,#app{
     height:100%;
     overflow: hidden;
     font-size:${mobile ? 0.8 : 1}rem;
   }
  body {
     background:${grayColor};
     color: ${fontColor};
   }
   img{
    max-width: 100%;
    display: block;
   }
   p{
     margin:0
   }
`;
export default GlobalStyle;
