import React, { useState, useEffect, useRef } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
} from "react-vis";
import styled from "styled-components";
import { whiteColor, blueAccent } from "../../../globals/colors";
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    color: ${whiteColor};
    background-color: ${blueAccent};
    padding: 0.5rem;
    border: 0;
    font-size: 0.8rem;
    cursor: pointer;
  }
`;
const Bar = ({ data, container }) => {
  const rows = ["Start", "Growth", "Scale", "Optimize"];
  const colors = ["#00bfd4", "#850963", "#ff5200", "#ffa323", "#329846"];
  const [size, setSize] = useState([300, 250]);
  const [barData, setBarData] = useState(
    data.map((item, index) => ({
      x: item.name,
      y: 1,
      color: colors[index],
    }))
  );
  const timeoutRef = useRef();
  const mounted = useRef(true);
  useEffect(() => {
    return () => (mounted.current = false);
  }, []);
  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      if (!mounted.current) return;
      setBarData(
        data.map((item, index) => ({
          x: item.name,
          y: item.score,
          color: colors[index],
        }))
      );
    }, 1000);
    return () => clearTimeout(timeoutRef.current);
  }, [data]);
  useEffect(() => {
    setTimeout(() => {
      if (!mounted.current) return;
      let width = container?.current?.clientWidth;
      console.log({ width, mounted });
      setSize([width * 0.7, width * 0.5]);
    });
  }, [container.current]);
  return (
    <Wrapper ref={container}>
      <XYPlot
        margin={{ left: 70, top: 50, bottom: 70 }}
        xType="ordinal"
        width={size[0]}
        height={size[1]}
        xDomain={["Mobilize", "Design", "Launch", "Adoption", "Value Creation"]}
        yDomain={[0, 100]}
        animation
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis tickLabelAngle={-45} />
        <YAxis
          tickTotal={4}
          tickValues={[0, 25, 50, 75, 100]}
          tickFormat={(v) => rows[v / 25]}
        />
        <VerticalBarSeries
          animation
          data={barData}
          colorType="literal"
          dontCheckIfEmpty
        />
      </XYPlot>
    </Wrapper>
  );
};
export default Bar;
