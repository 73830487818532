import pdfService from "./pdfService";
import { getBase64Image } from "./image";
import getPublicUrl from "../utilities/getPublicUrl";
import tableOfContent from "../content/report/tableOfContent.json";
import content from "../content/report/pages.json";
export const create = async (data, values, results) => {
  const name = `${values.company.toUpperCase()}`;
  let pageNumber = 1;
  const doc = pdfService.create();
  const pg1 = await getBase64Image(getPublicUrl("/doc/pg1.png"));
  pdfService.image(doc, 0, pg1, true);
  pdfService.headLine(
    doc,
    "Customer Onboarding Maturity Report",
    `PREPARED FOR: ${name}`
  );
  // pageNumber++;
  // pdfService.tableOfContent(doc, tableOfContent);
  // pdfService.pageNumber(
  //   doc,
  //   `${name} : CUSTOMER ONBOARDING PERFORMANCE REPORT`,
  //   pageNumber
  // );
  let index = -1;
  for (const page of content) {
    index++;
    doc.addPage();
    let yPos = 0;
    pageNumber++;
    pdfService.pageNumber(
      doc,
      `${name} : CUSTOMER ONBOARDING PERFORMANCE REPORT`,
      pageNumber
    );
    for (const { type, value, links, number, rect, showFor } of page.content) {
      const printableValue = injectUserInfo(value, values);
      if (showFor) {
        const [stage, level] = showFor.split(":");
        //put level here to see aall outputs for level
        //if ("optimize" !== level) continue;
        if (results[stage].level !== level) continue;
      }
      if (type === "H") {
        yPos = pdfService.header(doc, yPos, printableValue);
      } else if (type === "H1") {
        yPos = pdfService.header(doc, yPos, printableValue);
      } else if (type === "PB") {
        yPos = pdfService.paragraph(doc, yPos, printableValue, true);
      } else if (type === "P") {
        yPos = pdfService.paragraph(doc, yPos, printableValue, false, links);
      } else if (type === "B") {
        yPos = pdfService.bullet(doc, yPos, printableValue, number, rect);
      } else if (type === "BREAK") {
        doc.addPage();
        pageNumber++;
        yPos = 0;
        pdfService.pageNumber(
          doc,
          `${name} : CUSTOMER ONBOARDING PERFORMANCE REPORT`,
          pageNumber
        );
      } else if (type === "I") {
        let imageInfo = data[printableValue],
          fullWidth = true;
        if (!imageInfo) {
          fullWidth = false;
          imageInfo = await getBase64Image(getPublicUrl(printableValue));
        }
        yPos = pdfService.image(doc, yPos, imageInfo, false, fullWidth);
      }
    }
  }
  doc.save(`${name} onboarding performance report.pdf`);

  return doc;
};
const injectUserInfo = (value, userInfo) => {
  if (Array.isArray(value)) {
    return value.map((v) => v.replace("[COMPANY NAME]", userInfo.company));
  }
  return value ? value.replace("[COMPANY NAME]", userInfo.company) : value;
};
