import { getAuth, getAuthProviders, getDb } from "./remote";
import { update } from "../api/userEntry";
export const getConfig = (user) => {
  const auth = getAuth();
  return {
    auth,
    uiConfig: {
      autoUpgradeAnonymousUsers: true,
      signInFlow: "popup",
      credentialHelper: "none",
      signInOptions: getAuthProviders(),
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          return false;
        },
        signInFailure: async (error) => {
          // For merge conflicts, the error.code will be
          // 'firebaseui/anonymous-upgrade-merge-conflict'.
          if (error.code != "firebaseui/anonymous-upgrade-merge-conflict")
            return;

          // The credential the user tried to sign in with.
          const cred = error.credential;
          // If using Firebase Realtime Database. The anonymous user data has to be
          // copied to the non-anonymous user.
          let db = getDb();

          let snapshot = await db.ref("users/" + user.uid).once("value");
          const data = snapshot.val();
          await db.ref("users/" + user.uid).remove();
          // await user.delete();
          const auth = getAuth();
          await auth.signInWithCredential(cred);
          const currentUser = auth.currentUser;
          snapshot = await db.ref("users/" + currentUser.uid).once("value");
          const initialData = snapshot.val();
          await update(currentUser.uid, {
            ...initialData,
            ...data,
            values: { ...initialData.values, ...data.values },
          });
        },
      },
    },
  };
};
export const signOut = () => getAuth().signOut();
export const signInAnonymously = async () => {
  const auth = getAuth();
  const info = await auth.signInAnonymously();
  return info.user;
};
export const signInWithCustomToken = async (token) => {
  const auth = getAuth();
  const info = await auth.signInWithCustomToken(token);
  return info.user;
};
