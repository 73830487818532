import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { brandColor, whiteColor } from "../globals/colors";
import sleepAsync from "../utilities/sleepAsync";
import getPublicUrl from "../utilities/getPublicUrl";
const Wrapper = styled.div`
  position: relative;
  height: 7rem;
  .box {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    display: flex;
    .header-bar {
      max-height: 100%;
      color: ${whiteColor};
      background: ${brandColor};
      flex-grow: 1;
      padding: 0 3%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      display: flex;
      padding: 1rem;
      overflow: hidden;
      .logo {
        overflow: hidden;
        display: flex;
        width: 260px;
        max-width: 50%;
      }
      .title {
        font-size: 1.8rem;
        @media only screen and (max-width: 1000px) {
          display: none;
        }
      }
    }
  }
`;
const GlobalHeader = ({
  animate,
  onAnimationComplete,
  sleep,
  children,
  right,
}) => {
  const controls = useAnimation();
  const runHideAnimation = async () => {
    if (sleep) await sleepAsync(sleep);
    await controls.start({
      top: "0",
      position: "relative",
      transition: { duration: 0.5 },
    });
    if (onAnimationComplete) onAnimationComplete();
  };
  useEffect(() => {
    if (!animate) return;
    runHideAnimation();
  }, [animate]);

  return (
    <Wrapper>
      <motion.div
        className={"box"}
        initial={{
          top: animate ? "-100%" : "0",
          position: animate ? "absolute" : "relative",
        }}
        animate={controls}
      >
        <div className={"header-bar"}>
          <div className={"logo"}>
            <img src={getPublicUrl("/logo-invert.png")} />
          </div>
          <div className={"title"}>
            Customer Onboarding Performance Assessment
          </div>
          <div>{right}</div>
        </div>
        {children}
      </motion.div>
    </Wrapper>
  );
};
export default GlobalHeader;
