import React, { useState, useEffect } from "react";
import GlobalHeader from "../../components/GlobalHeader";
import styled from "styled-components";
import SurveyBox from "../../components/SurveyBox";
import LoadingBar from "../../components/LoadingBar";
import TimeEstimate from "../../components/TimeEstimate";
import { blueAccent } from "../../globals/colors";
import screenTypes from "./secreenTypes";
import { get as getSurveyQuestion } from "../../api/surveyQuestions";
import { getEstimateDetails } from "../../services/timeEstimate";
import data from "../../content/questions.json";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Survey = ({
  nextPage,
  previousPage,
  values,
  setValues,
  onLoginRequest,
  screenIndex,
  setScreenIndex,
}) => {
  const [showContentBox, setShowContentBox] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [screens, setScreens] = useState();
  const [progressInfo, setProgressInfo] = useState();
  const loadQuestion = async () => {
    // const question = await getSurveyQuestion();
    const question = data;
    setScreens(question);
  };
  useEffect(() => {
    loadQuestion();
  }, []);
  useEffect(() => {
    setProgressInfo(getEstimateDetails(screenIndex, screens));
  }, [screenIndex, screens]);
  const next = () => {
    const isLastScreen = screens.length === screenIndex + 1;
    if (!isLastScreen) {
      setScreenIndex(screenIndex + 1);
    } else {
      nextPage(values);
    }
  };
  const previous = () => {
    const isFirstScreen = 0 === screenIndex;
    if (!isFirstScreen) {
      setScreenIndex(screenIndex - 1);
    } else {
      previousPage(values);
    }
  };
  const getScreen = () => {
    const screenDetail = screens[screenIndex];
    const Screen = screenTypes[screenDetail.screenType];
    return (
      <Screen
        key={screenIndex}
        index={screenIndex}
        {...screenDetail}
        next={next}
        previous={previous}
        values={values}
        setValues={setValues}
      />
    );
  };
  return (
    <Wrapper>
      <GlobalHeader
        animate
        sleep={300}
        onAnimationComplete={() => setShowContentBox(true)}
        right={<TimeEstimate min={progressInfo?.timeLeft} />}
      >
        <LoadingBar progress={showContentBox ? progressInfo?.percentage : 0} />
      </GlobalHeader>
      {showContentBox && (
        <SurveyBox
          animate
          onAnimationComplete={() => setShowContent(true)}
          actions={
            <div
              style={{
                color: blueAccent,
                fontWeight: 600,
                margin: ".5rem",
                cursor: "pointer",
              }}
              onClick={onLoginRequest}
            >
              SAVE FOR LATER
            </div>
          }
        >
          {showContent && getScreen()}
        </SurveyBox>
      )}
    </Wrapper>
  );
};
export default Survey;
