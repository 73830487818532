const getPointsSummary = (values) => {
  console.log({ values });
  const stages = Object.keys(stagesDetail);
  const summary = stages.reduce((acc, stageName) => {
    const details = stagesDetail[stageName];
    const points = getLeversPointSum(values, details.levers);
    const pointStep = details.steps.find((step) => step > points.sum);
    let level;
    if (pointStep) {
      const stepIndex = details.steps.indexOf(pointStep);
      level = scale[stepIndex];
    } else {
      level = scale[scale.length - 1];
    }
    const score = (points.sum / details.max) * 100;
    return {
      ...acc,
      [stageName]: {
        name: details.name,
        ...points,
        level,
        score: score > 100 ? 100 : score,
      },
    };
  }, {});
  return summary;
};
const getLeversPointSum = (values, levers) => {
  const leverIds = Object.keys(levers);
  return leverIds.reduce(
    (acc, leverId) => {
      const points = levers[leverId].points;
      let pointValue = 0;
      const value = values[leverId];
      if (Array.isArray(points) && value) {
        pointValue = points[value - 1];
      } else if (typeof points === "object" && value) {
        pointValue = value
          .split("::")
          .reduce(
            (sum, id) => (points.hasOwnProperty(id) ? sum + points[id] : sum),
            0
          );
      } else if (value) {
        pointValue = value.split("::").length;
        if (points) pointValue = pointValue * points;
      }
      return {
        ...acc,
        [leverId]: pointValue,
        sum: acc.sum + pointValue,
      };
    },
    { sum: 0 }
  );
};

const scale = ["initial", "growth", "scale", "optimize"];
const stagesDetail = {
  mobilize: {
    name: "Mobilize",
    steps: [5, 9, 15],
    max: 16,
    levers: {
      structure: {
        name: "Organizational Structure",
        points: {
          some_job: 1,
          support_team: 2,
          professinal_services: 2,
          own_departement: 6,
          own_organization: 8,
        },
      },
      collaboration: {
        name: "Cross Functional Collaboration",
        points: {
          none: 1,
          between_departments: 2,
          intradepartmental: 3,
          technology: 4,
        },
      },
      strategy: {
        name: "Customer Success as a Strategy",
        points: {
          reactive: 1,
          proactive: 2,
          critical_to_sales_service: 3,
          critical_to_org: 4,
        },
      },
    },
  },
  design: {
    name: "Design",
    steps: [7, 16, 25],
    max: 27,
    levers: {
      touchpoints: {
        name: "Touchpoints",
        points: {
          one_size_fit_all: 1,
          webinars_and_one_on_one: 2,
          segmented_multi_touch_strategy: 3,
          multi_touch_strategy: 4,
        },
      },
      standard: {
        name: "Standardized Process",
        points: { no_process: 1, standardized: 2, tools: 6, advanced: 8 },
      },
      engagement: {
        name: "Customer Engagement, Project Tracking, System Automation",
        points: 1,
      },
      projectCollaboration: {
        name: "Project Collaboration",
        points: {
          email: 1,
          phone: 1,
          portal: 1,
          slack: 1,
          repository: 1,
          projectmanagement: 1,
          taskray: 2,
          support: 2,
          salesforce: 2,
          crm: 2,
        },
      },
    },
  },
  launch: {
    name: "Launch",
    steps: [2, 6, 10],
    max: 11,
    levers: {
      customerManagement: {
        name: "Proactive Customer Management",
        points: {
          template: 1,
          training: 2,
          custom_training: 2,
          release_training: 3,
          survey: 3,
        },
      },
    },
  },
  adoption: {
    name: "Adoption",
    steps: [2, 7, 14],
    max: 18,
    levers: {
      revenue: {
        name: "Second Order Revenue",
        points: {
          none: 1,
          template: 2,
          champion: 6,
          standards: 3,
        },
      },
      metrics: {
        name: "Metrics",
        points: {
          csat: 1,
          value: 4,
          length: 1,
        },
      },
    },
  },
  value: {
    name: "Value Creation",
    steps: [2, 7, 12],
    max: 14,
    levers: {
      value: {
        name: "Second Order Revenue",
        points: {
          database: 1,
          bestPractice: 2,
          expansion: 5,
          qbr: 6,
        },
      },
    },
  },
};
export default getPointsSummary;
