export const getImage = async (url) => {
  const img = new Image();
  const promise = new Promise((resolve) => {
    img.onload = () => resolve(img);
    img.src = url;
    console.log({ img });
  });
  return promise;
};
export const getBase64Image = async (url) => {
  console.log({ url });
  const img = await getImage(url);
  console.log({ img });
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);
  var dataUrl = canvas.toDataURL("image/png");
  return {
    dataUrl,
    aspectRatio: img.width / img.height,
  };
};
