import Options from "./Options";
import CustomForm from "./CustomForm";
import CheckBoxOptions from "./CheckBoxOptions";
import RadioOptions from "./RadioOptions";
const questionTypes = {
  Options,
  CustomForm,
  CheckBoxOptions,
  RadioOptions,
};
export default questionTypes;
