import config from "./config";
export const headLine = (doc, text, subText) => {
  doc.setFontSize(36);
  doc.setFont("Raleway", "normal");
  doc.setTextColor(255, 255, 255);
  const lines = doc.splitTextToSize(text, 7);
  doc.setLineHeightFactor(1.3);
  doc.text(lines, 0.8, 3);
  doc.setFont("Raleway", "bold");
  doc.setLineHeightFactor(1.2);
  doc.setFontSize(12);
  doc.text(subText, 0.8, 4);
  doc.setTextColor(0, 0, 0);
};
export const header = (doc, startHeight, text, isBold, multiplier = 1) => {
  doc.setFontSize(config.headerFontSize * multiplier);
  doc.setFont("Raleway", isBold ? "bold" : "normal");
  const lines = doc.splitTextToSize(text, config.maxLineWidth);
  var textHeight =
    (lines.length * (config.headerFontSize * multiplier) * config.lineHeight) /
    config.ptsPerInch;
  doc.text(
    lines,
    config.margin,
    startHeight + (config.margin + 0.75 * (config.oneHeaderHeight * multiplier))
  );
  return startHeight + textHeight;
};
export const bullet = (doc, startHeight, text, number, rect, isBold) => {
  doc.setFont("Raleway", isBold ? "bold" : "normal");
  doc.setFontSize(config.fontSize);
  const lines = doc.splitTextToSize(text, config.maxLineWidth - 0.5);
  var textHeight =
    (lines.length * config.fontSize * config.lineHeight) / config.ptsPerInch;
  if (number) {
    doc.text(
      number,
      config.margin + 0.25,
      startHeight + (config.margin + 2 * config.oneLineHeight)
    );
  } else if (rect) {
    doc.rect(
      config.margin + 0.25,
      startHeight + (config.margin + 1.5 * config.oneLineHeight),
      0.135,
      0.135,
      "F"
    );
    doc.setFillColor(251, 251, 251);
    doc.rect(
      config.margin + 0.25 + 0.01,
      startHeight + (config.margin + 1.5 * config.oneLineHeight) + 0.01,
      0.11,
      0.11,
      "F"
    );
  } else {
    doc.setFontSize(config.fontSize * 2);
    doc.text(
      "•",
      config.margin + 0.25,
      startHeight +
        (config.margin + 2 * config.oneLineHeight) +
        config.oneLineHeight / 3
    );
  }

  doc.setFontSize(config.fontSize);
  doc.text(
    lines,
    config.margin + 0.5,
    startHeight + (config.margin + 2 * config.oneLineHeight)
  );
  return startHeight + textHeight + config.oneLineHeight - 0.15;
};
export const image = (doc, yPos, info, fullpage, fullWidth) => {
  const mediaHeight = 7.5 / info.aspectRatio;
  doc.addImage(
    info.dataUrl,
    "PNG",
    fullpage || fullWidth ? 0 : 0.75,
    fullpage ? yPos : yPos + 0.8,
    fullpage ? 8.6 : 7.75,
    fullpage ? 11 : mediaHeight
  );
  return yPos + mediaHeight;
};
export const pageNumber = (doc, value, pageNumber) => {
  doc.setFontSize(9);
  doc.setFont("Raleway", "bold");
  doc.text(value, 0.75, 10.5);
  doc.text("" + pageNumber, 7.75, 10.5);
};

export const paragraph = (doc, startHeight, text, isBold, links) => {
  doc.setFont("Raleway", isBold ? "bold" : "normal");
  doc.setFontSize(config.fontSize);
  let textHeight = 0;
  text.map((p) => {
    doc.setFont("Raleway", isBold ? "bold" : "normal");
    const lines = doc.splitTextToSize(p, config.maxLineWidth);
    for (const line of lines) {
      const paragraphStartHeight =
        startHeight + textHeight + (config.margin + 1.5 * config.oneLineHeight);
      printLineWithDecoration(
        line,
        doc,
        config.margin,
        paragraphStartHeight,
        links
      );
      textHeight += (config.fontSize * config.lineHeight) / config.ptsPerInch;
    }

    // doc.text(removeMarkers(lines), config.margin, paragraphStartHeight);
    // doc.setFont("Raleway", "bold");
    // doc.text(applybold(lines), config.margin, paragraphStartHeight);
    // // doc.text(applybold(lines), config.margin, paragraphStartHeight);
    // textHeight +=
    //   (lines.length * config.fontSize * config.lineHeight) / config.ptsPerInch;
    textHeight += 0.1;
  });
  return startHeight + textHeight;
};
const printLineWithDecoration = (
  line,
  doc,
  margin,
  paragraphStartHeight,
  links
) => {
  if (line.includes("`")) {
    const lineParts = line.split("`");
    let isSpecial = false;
    let startPos = margin;
    for (const linePart of lineParts) {
      let isLink = false;
      if (isSpecial) {
        if (links && links.hasOwnProperty(linePart)) {
          isLink = true;
        } else {
          doc.setFont("Raleway", "bold");
        }
      } else {
        doc.setFont("Raleway", "normal");
      }
      isSpecial = !isSpecial;
      if (isLink) {
        doc.setTextColor(0, 97, 127);
        doc.textWithLink(linePart, startPos, paragraphStartHeight, {
          url: links[linePart],
        });
      } else {
        doc.text(linePart, startPos, paragraphStartHeight);
      }
      startPos += (doc.getStringUnitWidth(linePart) * config.fontSize) / 72;
      doc.setTextColor(0, 0, 0);
    }
  } else {
    doc.text(line, margin, paragraphStartHeight);
  }
};
const removeMarkers = (lines) => {
  const cleanLines = [];
  for (const line of lines) {
    cleanLines.push(line.replace(/\`/g, ""));
  }
  console.log({ cleanLines });
  return cleanLines;
};
const applybold = (lines) => {
  const boldLines = [];
  for (const line of lines) {
    if (line.includes("`")) {
      const lineParts = line.split("`");
      let isBold = false;
      let boldLine = "";
      for (const linePart of lineParts) {
        if (isBold) {
          boldLine += linePart;
        } else {
          boldLine += linePart.replace(/./g, "i");
        }
        isBold = !isBold;
      }
      boldLines.push(boldLine);
    } else {
      boldLines.push(" ");
    }
  }
  return boldLines;
};
const containsHighlights = (lines) => {
  for (const line of lines) {
    if (line.includes("`")) return true;
  }
  return false;
};
