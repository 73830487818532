import React from "react";
import { hot } from "react-hot-loader";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "./Themes/default/Container";
import UserApp from "./UserApp";
import ReportApp from "./ReportApp";
import { createGlobalStyle } from "styled-components";
import "./services/analytics";
const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Raleway", "Helvetica", "Arial", sans-serif;
  }
`;
const App = () => {
  return (
    <Container baseStyle={<CssBaseline />}>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path={"/:id"} component={ReportApp} />
          <Route component={UserApp} />
        </Switch>
      </Router>
    </Container>
  );
};
export default hot(module)(App);
