import React, { useState, useEffect } from "react";
import Report from "./apps/Report";
import { get as getEntry } from "./api/userEntry";
import getPointsSummary from "./apps/Summary/util/getPointsSummary";
const ReportApp = ({
  match: {
    params: { id },
  },
}) => {
  const [userInfo, setUserInfo] = useState();
  const [results, setResults] = useState();
  const getUserEntry = async () => {
    if (!id) return;
    const res = await getEntry(id);
    setUserInfo(res);
    console.log(getPointsSummary(res.values));
    setResults(getPointsSummary(res.values));
  };
  useEffect(() => {
    getUserEntry();
  }, []);
  return userInfo ? (
    <Report info={userInfo} results={results} />
  ) : (
    <div>loading</div>
  );
};
export default ReportApp;
