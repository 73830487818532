import React, { useEffect } from "react";
import styled from "styled-components";
import { brandColor, whiteColor } from "../../globals/colors";
import { motion, useAnimation } from "framer-motion";
import { isMobile } from "../../services/device";
import getPublicUrl from "../../utilities/getPublicUrl";
const mobile = isMobile();
const SplashScreenWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: ${whiteColor};
    background: ${brandColor};
    padding: 1rem 1rem 0;
    @media only screen and (min-width: 1000px) {
      padding: 2rem;
    }
    height: 100%;
    .header {
      width: 100%;
      img {
        width: 400px;
        max-width: 80%;
      }
    }
    .illustration {
      text-align: center;
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      padding: 0 1.5rem;
      max-width: 1100px;
      img {
        height: 100%;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin: 1rem;
    }
    h1 {
      margin: 1rem 0 0 0;
      padding: 0;
      font-size: 1.7rem;
      font-weight: 400;
      font-size: 2rem;
      max-width: 650px;
      @media only screen and (min-width: 1000px) {
        position: relative;
        top: 5rem;
        left: 2rem;
        letter-spacing: 2px;
        font-size: 1.7rem;
        font-size: 3rem;
      }
    }
    p {
      max-width: 35rem;
      font-size: 1.2rem;
    }
  }
`;
const SplashScreen = ({ sidebarVisible, onMouseMove, hide, onHidden }) => {
  const coordinatesToAnimate = mobile ? "top" : "left";
  const controls = useAnimation();
  useEffect(() => {
    if (!hide) return;
    runHideAnimation();
  }, [hide]);
  const runHideAnimation = async () => {
    await controls.start({
      [coordinatesToAnimate]: "-100%",
      transition: { duration: 0.6 },
    });
    onHidden();
  };
  return (
    <SplashScreenWrapper
      isFullWidth={!sidebarVisible}
      onMouseMove={onMouseMove}
    >
      <motion.div
        className={"box"}
        initial={{ [coordinatesToAnimate]: 0 }}
        animate={controls}
      >
        <div className={"header"}>
          <img src={getPublicUrl("/logo-invert.png")} />
        </div>
        <div className={"content"}>
          <h1>Customer Onboarding Performance Assessment</h1>
          {/* <p>
            An interactive survey of industry best practices and how they can
            change the way you do business.
          </p> */}
        </div>
        <div className={"illustration"}>
          <img
            src={getPublicUrl(
              "/taskray-customer-onboarding-maturity_no-words.svg"
            )}
          />
        </div>
      </motion.div>
    </SplashScreenWrapper>
  );
};

export default SplashScreen;
