// import "./init";
import tableOfContent from "./tableOfContent";
import create from "./create";
import { headLine, header, paragraph, bullet, image, pageNumber } from "./make";
export default {
  create,
  tableOfContent,
  headLine,
  header,
  paragraph,
  bullet,
  image,
  pageNumber,
};
