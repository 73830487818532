const pageWidth = 8.5,
  lineHeight = 1.2,
  margin = 0.75,
  maxLineWidth = pageWidth - margin * 2,
  fontSize = 12,
  headerFontSize = 18,
  ptsPerInch = 72,
  oneLineHeight = (fontSize * lineHeight) / ptsPerInch,
  oneHeaderHeight = (headerFontSize * lineHeight) / ptsPerInch;
export default {
  pageWidth,
  lineHeight,
  margin,
  maxLineWidth,
  fontSize,
  headerFontSize,
  ptsPerInch,
  oneLineHeight,
  oneHeaderHeight,
};
