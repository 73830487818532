import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import { brandColor } from "../../../../globals/colors";
import getPublicUrl from "../../../../utilities/getPublicUrl";
const CardWrapper = styled(Paper)`
  position: relative;
  margin: 0.5rem;
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .selected {
    height: 0;
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 5px;
  }
  .icon-container {
    max-width: 60%;
    max-height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    div {
      max-width: 100%;
      max-height: 100%;
      img {
        width: 100%;
      }
    }
  }
  h3 {
    margin: 0.5rem;
    font-size: 1rem;
    color: #3d556b;
  }
`;
const Card = ({ id, label, icon, selected, onSelect }) => {
  const [hover, setHover] = useState(false);
  return (
    <CardWrapper
      elevation={hover ? 3 : 1}
      square
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onSelect}
    >
      {selected && (
        <div className={"selected"}>
          <CheckCircleTwoToneIcon style={{ color: brandColor }} />
        </div>
      )}
      <div className={"icon-container"}>
        <div>
          <img src={getPublicUrl(icon)} />
        </div>
      </div>
      <h3>{label}</h3>
    </CardWrapper>
  );
};
export default Card;
