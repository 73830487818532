import config from "./config";
const tableOfContent = (doc, data) => {
  doc.addPage();
  doc.setFontSize(18);
  doc.setFont("Raleway", "bold");
  doc.text("Table of ContentsWw", 0.8, 1);
  doc.setFont("Raleway", "normal");
  doc.setFontSize(12);
  let yPosition = 1.5;
  data.forEach((item) => {
    let xPosition = item.indent ? 1 : 0.8;
    doc.textWithLink(item.value + "Ww", xPosition, yPosition, {
      pageNumber: item.page,
    });
    doc.text("" + item.page, 7.5, yPosition);
    yPosition += config.oneLineHeight;
  });
};
export default tableOfContent;
