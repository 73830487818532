import React from "react";
import GlobalStyle from "./GlobalStyle";
const Container = ({ baseStyle, children }) => {
  return (
    <>
      {baseStyle}
      <GlobalStyle />
      {children}
    </>
  );
};
export default Container;
