import React, { useEffect, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
const FormBlock = styled.div`
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex-grow: 1;
    margin: 0.3rem;
    box-sizing: border-box;
    min-width: calc(50% - 0.6rem);
  }
`;
const CustomForm = ({
  fields,
  values,
  setValue,
  showErrors,
  setInvalidValues,
}) => {
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const fieldErrors = fields
      .filter((field) => field.id)
      .reduce((acc, field) => {
        const fieldValidationValue = validateField(field);
        return {
          ...acc,
          [field.id]: fieldValidationValue,
        };
      }, {});
    setErrors(fieldErrors);
  }, [values]);
  useEffect(() => {
    setInvalidValues(Object.keys(errors).filter((id) => errors[id]));
  }, [errors]);
  const validateField = ({ id, required, validation, error }) => {
    const value = values?.[id];
    if (id === "terms" && !value)
      return {
        error: true,
        helperText: "required",
      };
    const isEmpty = value === "" || value === undefined;
    const hasValidation = !!validation;
    if (!required && isEmpty) return false;
    if (!required && !hasValidation) return false;

    if (isEmpty && required)
      return { error: true, ...(error && { helperText: error }) };
    if (hasValidation) {
      const invalidValidation = validation.find(
        ({ check }) => !new RegExp(check).test(value)
      );
      return (
        !!invalidValidation && {
          error: true,
          helperText: invalidValidation.error,
        }
      );
    }
    return false;
  };
  return (
    <FormBlock>
      {fields.map(
        (
          { type, options, id, value, required, validation, error, ...props },
          index
        ) => {
          console.log({
            type,
            options,
            id,
            value,
            required,
            validation,
            error,
            props,
          });
          if (type === "terms")
            return (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values[id]}
                      onChange={(event) => setValue(id, !values[id])}
                    />
                  }
                  label={<a>{props.label}</a>}
                />
                <div>
                  <a
                    href={"https://taskray.com/privacy-policy/"}
                    target={"_blank"}
                  >
                    privacy policy
                  </a>
                </div>
              </div>
            );
          if (type === "empty")
            return <div className="empty" key={index}></div>;
          return (
            <TextField
              key={index}
              type={type}
              select={!!options}
              {...props}
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus={index === 0}
              onChange={(event) => setValue(id, event.target.value)}
              value={values?.[id] || ""}
              required={required}
              {...(showErrors && errors[id])}
            >
              {options &&
                options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </TextField>
          );
        }
      )}
    </FormBlock>
  );
};
export default CustomForm;
