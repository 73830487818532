import React from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  margin-top: 0.5rem;
  min-width: 7rem;
  color: #ffffffeb;
  h3,
  h4,
  h5 {
    padding: 0;
    margin: 0;
  }
  h3 {
    font-size: 2rem;
    line-height: 3.5rem;
    span {
      font-size: 1rem;
      display: inline-block;
      margin-left: -0.5rem;
    }
  }
  h5 {
    font-size: 0.6rem;
    position: relative;
    bottom: -0.9rem;
  }
  h4 {
    font-size: 0.6rem;
    position: relative;
    top: -0.9rem;
    left: 0.3rem;
  }
`;
const TimeEstimate = ({ min }) => {
  return (
    <Wrapper>
      <h5>Estimated</h5>
      <h3>
        {min ?? "∞"} <span>min</span>
      </h3>
      <h4>
        until your custom <br />
        onboarding Report
      </h4>
    </Wrapper>
  );
};
export default TimeEstimate;
