import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { blueAccent } from "../globals/colors";
import sleepAsync from "../utilities/sleepAsync";
const Wrapper = styled.div`
  position: relative;
  min-height: 0.5rem;
  width: 100%;
  background-color: #8080806b;
  .progress {
    content: "";
    position: absolute;
    background-color: ${blueAccent};
    width: 0%;
    height: 100%;
    z-index: 1;
    opacity: 0.7;
  }
`;
const LoadingBar = ({ progress }) => {
  const controls = useAnimation();
  // const [currentPosition,setCurrentPosition] = useState(0);
  const runHideAnimation = async () => {
    await sleepAsync(500);
    await controls.start({
      width: progress + "%",
      transition: { duration: 0.5 },
    });
  };
  useEffect(() => {
    runHideAnimation();
  }, [progress]);
  return (
    <Wrapper>
      <motion.div
        className={"progress"}
        initial={{ width: 0 }}
        animate={controls}
      ></motion.div>
    </Wrapper>
  );
};
export default LoadingBar;
