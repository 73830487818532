import React, { useRef, useState } from "react";
import GlobalHeader from "../../components/GlobalHeader";
import styled from "styled-components";
import LoadingBar from "../../components/LoadingBar";
import SurveyBox from "../../components/SurveyBox";
import data from "../../content/questions.json";
import screenTypes from "../Survey/secreenTypes";
import Bar from "../Summary/charts/Bar";
import StagesSummary from "../Summary/StagesSummary";
import { create } from "../../services/report";
import CircularProgress from "@material-ui/core/CircularProgress";
import domtoimage from "dom-to-image";
import analytics from "../../services/analytics";
const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  overflow: auto;
  padding: 2rem 0;
  min-height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ScreenContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 900px;
  background: #fff;
  box-shadow: 1px 1px 2px 1px #00000021;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Report = ({ info, results }) => {
  const [screens] = useState(data);
  const BarContainer = useRef();
  const [reportLoading, setReportLoading] = useState(false);
  const getScreen = (screenDetail, index) => {
    const Screen = screenTypes[screenDetail.screenType];
    return (
      <ScreenContainer>
        <Screen
          key={index}
          {...screenDetail}
          next={() => undefined}
          values={info.values}
          setValues={() => undefined}
          readOnly
        />
      </ScreenContainer>
    );
  };
  const getContainerImage = async (container) => {
    const { width, height } = container.current.getBoundingClientRect();
    const aspectRatio = width / height;
    const dataUrl = await domtoimage.toPng(container.current);
    return { dataUrl, aspectRatio };
  };
  const downloadReport = async () => {
    analytics().logEvent("download_report");
    setReportLoading(true);
    const data = {
      bar: await getContainerImage(BarContainer),
    };
    await create(data, info.values, results);
    setReportLoading(false);
  };
  return (
    <Wrapper>
      <GlobalHeader>
        <LoadingBar progress={100} />
      </GlobalHeader>
      <Content>
        {screens.map((screen, index) => getScreen(screen, index))}

        {results && (
          <ScreenContainer>
            <Bar
              data={Object.keys(results).map((key) => ({
                name: results[key].name,
                score: results[key].score,
              }))}
              container={BarContainer}
            />
            <div style={{ padding: "1rem" }}>
              <StagesSummary results={results} />
            </div>
          </ScreenContainer>
        )}
        <button onClick={downloadReport}>
          {reportLoading ? (
            <CircularProgress size={"1rem"} />
          ) : (
            "Download Report"
          )}
        </button>
      </Content>
    </Wrapper>
  );
};
export default Report;
