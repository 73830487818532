import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { whiteColor } from "../globals/colors";
import sleepAsync from "../utilities/sleepAsync";
import { isMobile } from "../services/device";
const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ scrollable }) => (!scrollable ? "overflow:hidden;" : "")}
  justify-content: ${({ scrollable }) =>
    scrollable ? "flex-start" : "center"};
  .paper-box {
    overflow: visible;
    width: ${isMobile() ? "100%" : "90%"};
    max-width: 70rem;
    /* min-height: 50rem; */
    padding: 0;
    background: ${whiteColor};
    box-shadow: 1px 1px 2px 1px #00000021;
  }
`;
const SurveyBox = ({
  animate,
  onAnimationComplete,
  sleep,
  children,
  actions,
  scrollable,
}) => {
  const controls = useAnimation();
  const [showActions, setShowActions] = useState(false);
  const runHideAnimation = async () => {
    if (sleep) await sleepAsync(sleep);
    await controls.start({
      width: "90%",
      height: "90%",
      transition: { duration: 0.5 },
    });
    setShowActions(true);
    if (onAnimationComplete) onAnimationComplete();
  };
  useEffect(() => {
    if (!animate) return;
    runHideAnimation();
  }, [animate]);

  return (
    <Wrapper rendered={showActions} scrollable={scrollable}>
      <motion.div
        className={"paper-box"}
        initial={{
          ...(animate && { width: "0%", height: "0%" }),
        }}
        animate={controls}
      >
        {children}
        {showActions && <div>{actions}</div>}
      </motion.div>
    </Wrapper>
  );
};
export default SurveyBox;
