import { getDb } from "./remote";
export const get = async (key) => {
  const remoteDb = getDb();
  const snapshot = await remoteDb.ref(`/${key}`).once("value");
  return snapshot.val();
};
export const set = async (key, data) => {
  const remoteDb = getDb();
  await remoteDb.ref(key).set(data);
};
