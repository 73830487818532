import React, { useState, useEffect } from "react";
import LandingPage from "./apps/LandingPage";
import Survey from "./apps/Survey";
import Summary from "./apps/Summary";
import Auth from "./apps/Auth";
import { getAuth } from "./services/remote";
import { signInAnonymously } from "./services/auth";
import { get as getEntry, update as updateEntry } from "./api/userEntry";
import analytics from "./services/analytics";

const pages = [LandingPage, Survey, Summary];

const UserApp = ({ location }) => {
  const urlParams = new URLSearchParams(location.search);

  const [activeIndex, setActiveIndex] = useState(0);
  const [screenIndex, setScreenIndex] = useState(0);
  const [values, setValues] = useState({});
  const [showAuth, setShowAuth] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const getUser = async () => (userInfo ? userInfo : await signInAnonymously());
  const getUserEntry = async (uid) => {
    const entry = await getEntry(uid);
    if (entry?.values) {
      setValues({ ...entry.values, values });
    }
    // if (entry?.activeIndex) {
    //   setActiveIndex(entry.activeIndex);
    // }
    // if (entry?.screenIndex) {
    //   setScreenIndex(entry.screenIndex);
    // }
  };
  useEffect(() => {
    const unregisterAuthObserver = getAuth().onAuthStateChanged((user) => {
      setUserInfo(user);
      if (user) {
        getUserEntry(user.uid);
      } else {
        setValues({});
        setActiveIndex(0);
        setScreenIndex(0);
        setShowAuth(false);
      }
    });
    return () => {
      unregisterAuthObserver();
    };
  }, []);
  const handleUpdates = async () => {
    const user = await getUser();
    if (screenIndex > 0 && !showAuth) {
      await updateEntry(user.uid, { activeIndex, screenIndex, values });
    }
  };
  const sendToPardot = async (values) => {
    if (!values.email) return;
    const user = await getUser();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("email", values.email);
    urlencoded.append("firstname", values.firstName);
    urlencoded.append("lastname", values.lastName);
    urlencoded.append("company", values.company);
    urlencoded.append("terms", values.terms);
    urlencoded.append("utm_source", urlParams.get("utm_source"));
    urlencoded.append("utm_medium", urlParams.get("utm_medium"));
    urlencoded.append("utm_campaign", urlParams.get("utm_campaign"));
    urlencoded.append("surveyId", user.uid);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      mode: "no-cors",
    };
    await fetch(
      "https://p.taskray.com/l/492861/2020-10-01/k635d",
      requestOptions
    );
  };
  useEffect(() => {
    if (activeIndex === 0) return;
    if (values.email) sendToPardot(values);
  }, [activeIndex, screenIndex]);
  useEffect(() => {
    console.log({ values });
    if (activeIndex === 0) return;
    handleUpdates();
  }, [activeIndex, screenIndex, values]);
  const next = (values) => {
    if (pages.length - 1 > activeIndex) setActiveIndex(activeIndex + 1);
  };
  const previous = (values) => {
    if (pages.length - 1 > activeIndex) setActiveIndex(activeIndex - 1);
  };
  const getActivePages = () => {
    const Page = pages[activeIndex];
    return (
      <>
        <Page
          nextPage={next}
          previousPage={previous}
          values={values}
          setValues={setValues}
          onLoginRequest={() => {
            analytics().logEvent("save_for_later");
            setShowAuth(true);
          }}
          screenIndex={screenIndex}
          setScreenIndex={setScreenIndex}
          reset={async () => {
            const user = await getUser();
            console.log({ user });
            await updateEntry(user.uid, {
              activeIndex: 0,
              screenIndex: 0,
              values: {},
            });
            setActiveIndex(0);
            setScreenIndex(0);
            // await handleUpdates();
          }}
        />
      </>
    );
  };

  return (
    <>
      {showAuth && (
        <Auth onCancel={() => setShowAuth(false)} userInfo={userInfo} />
      )}
      {getActivePages()}
    </>
  );
};
export default UserApp;
