import React, { useState, useEffect } from "react";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import styled from "styled-components";
const OptionsControl = styled(FormControl)`
  margin: 1rem;
`;
const CheckBoxOptions = ({
  id,
  items,
  setValue,
  values,
  title,
  subTitle,
  min,
  max,
  setInvalidValues,
  setShowErrors,
}) => {
  const [valuesChecked, setValuesChecked] = useState([]);
  useEffect(() => {
    setShowErrors(true);
  }, []);
  useEffect(() => {
    const newValues = values[id]?.length > 0 ? values[id].split("::") : [];
    let isValid = true;
    if (newValues.length < min?.value || newValues.length > max?.value) {
      isValid = false;
    }
    setInvalidValues(!isValid ? [id] : []);

    setValuesChecked(newValues);
  }, [values]);
  const handleCheck = (value) => {
    const newValues = valuesChecked.includes(value)
      ? valuesChecked.filter((v) => v !== value)
      : [...valuesChecked, value];
    setValue(id, newValues.join("::"));
  };
  return (
    <OptionsControl component="fieldset">
      <FormLabel component="legend">{title}</FormLabel>
      <FormGroup>
        {items.map(({ label, value }, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={valuesChecked.includes(value)}
                name={value}
                onChange={() => handleCheck(value)}
              />
            }
            label={label}
          />
        ))}
      </FormGroup>
      <FormHelperText>{subTitle}</FormHelperText>
    </OptionsControl>
  );
};
export default CheckBoxOptions;
