import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { blueAccent, whiteColor } from "../../globals/colors";
import { isMobile } from "../../services/device";
import analytics from "../../services/analytics";
const mobile = isMobile();
const ActionPromptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 1rem 3rem;
  min-width: 20rem;
  h2,
  p,
  button,
  div {
    max-width: 40rem;
    padding: 0;
    margin: 0;
  }
  div {
    width: 100%;
  }
  h2 {
    font-size: ${mobile ? 1.4 : 1.6}rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  p {
    font-weight: 400;
  }
  button {
    color: ${whiteColor};
    background-color: #00607f;
    padding: 1rem 4rem;
    margin-top: 1rem;
    border: 0;
    font-size: 1.1rem;
    cursor: pointer;
  }
`;
const variants = {
  hidden: { width: 0 },
  visible: { width: "35%" },
};
const ActionPrompt = ({ onClick }) => {
  const controls = useAnimation();
  useEffect(() => {
    controls.start({
      ...(mobile
        ? { height: "15%", minHeight: "17rem" }
        : { width: "30%", minWidth: "20rem" }),
      transition: { duration: 0.5 },
    });
  }, []);
  const handleClick = async () => {
    analytics().logEvent("get_started");
    controls.start({
      opacity: 0,
      transition: { duration: 0.5 },
    });
    onClick();
  };
  return (
    <motion.div
      initial={
        mobile
          ? { minHeight: "0rem", height: "0%" }
          : { width: "0%", minWidth: "0rem" }
      }
      animate={controls}
    >
      <ActionPromptWrapper>
        <h2>How does your company stack up?</h2>
        <p>
          Take our interactive assessment to find out where your company sits on
          our Customer Onboarding Maturity Scale and get a customized report
          with the actions you can take to improve your onboarding experience
          and best your competition.
        </p>
        <div>
          <button onClick={handleClick}>GET STARTED</button>
        </div>
      </ActionPromptWrapper>
    </motion.div>
  );
};

export default ActionPrompt;
