import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import SplashScreen from "./SplashScreen";
import ActionPrompt from "./ActionPrompt";
import { isMobile } from "../../services/device";
const mobile = isMobile();
const LandingPageWrapper = styled.div`
  display: flex;
  flex-direction: ${mobile ? "column" : "row"};
  height: 100%;
`;
const waitBeforeActionPromptInMils = 1000;
const LandingPage = ({ nextPage }) => {
  const [userIsActive, setUserIsActive] = useState(false);
  const [showActionPrompt, setShowActionPrompt] = useState(false);
  const [hideSplash, setHideSplash] = useState(false);
  const timeOut = useRef();
  const mounted = useRef(true);
  useEffect(() => {
    return () => (mounted.current = false);
  }, []);
  useEffect(() => {
    if (!userIsActive && !isMobile()) return;
    timeOut.current = setTimeout(
      () => mounted.current && setShowActionPrompt(true),
      waitBeforeActionPromptInMils * (isMobile() ? 2 : 1)
    );
    return () => clearTimeout(timeOut.current);
  }, [userIsActive, isMobile]);
  return (
    <LandingPageWrapper>
      <SplashScreen
        onMouseMove={() => setUserIsActive(true)}
        hide={hideSplash}
        onHidden={nextPage}
      />
      {showActionPrompt && <ActionPrompt onClick={() => setHideSplash(true)} />}
    </LandingPageWrapper>
  );
};

export default LandingPage;
