const getDurationSum = (acc, { duration, ...props }) => acc + duration;
export const getEstimateDetails = (screenIndex, screens) => {
  let timeLeft, percentage;
  if (screens) {
    const totalTime = screens.reduce(getDurationSum, 0);
    let secondLeft = screens.slice(screenIndex).reduce(getDurationSum, 0);
    percentage = 100 - (secondLeft / totalTime) * 100;
    const minutesLeft = Math.ceil(secondLeft / 60);
    timeLeft = `${minutesLeft < 10 ? "0" : ""}${minutesLeft}`;
  }
  return {
    percentage,
    timeLeft,
  };
};
