import React from "react";
import styled from "styled-components";
import { whiteColor, blueAccent, grayColor } from "../../globals/colors";

const ActionButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem;
  button {
    margin-left: 0.5rem;
    padding: 1rem;
    margin-top: 1rem;
    border: 0;
    font-size: 1.1rem;
    font-weight: 500;
    text-align:center;
    min-width: 10rem;
    cursor: pointer;
    text-transform: uppercase;
    color: ${whiteColor};
    background-color: ${blueAccent};
    &:disabled {
      background-color: ${grayColor};
    }
    &.reverse {
      color: ${blueAccent};
      background-color: ${whiteColor};
      padding: 1rem;
      font-size: 1rem;
      opacity: 0.5;
      &:hover {
        opacity: 0.95;
      }
      &:disabled {
        color: ${grayColor};
        opacity: 1;
      }
      }
    }
  }
`;
export default ActionButton;
