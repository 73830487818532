import React from "react";
import content from "../../content/report/summary.json";
const StagesSummary = ({ results }) => {
  const summaryOptions = content.details;
  return (
    <>
      {content.order.map((row, index) => {
        const info = results[row];
        return (
          <div key={index}>
            <h3>{info.name}</h3>
            <h4>Your Maturity Level: {info.level}</h4>
            <p>{summaryOptions[row][info.level]}</p>
          </div>
        );
      })}
    </>
  );
};
export default StagesSummary;
