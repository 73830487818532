import React, { useState, useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {
  getConfig,
  getUser,
  signOut,
  signInWithCustomToken,
} from "../../services/auth";
import styled from "styled-components";
import { grayColor, blueAccent } from "../../globals/colors";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Avatar from "@material-ui/core/Avatar";
import getPublicUrl from "../../utilities/getPublicUrl";
const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000094;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Box = styled.div`
  width: 25rem;
  height: 30rem;
  box-shadow: 1px 1px 2px #4e4e4e69;
  padding: 1rem;
  background-color: ${grayColor};
  position: relative;
  .MuiAvatar-root {
    width: 90px;
    height: 90px;
    text-align: center;
    margin: auto;
  }
  .close-bt {
    top: -2.5rem;
    position: absolute;
    right: -1rem;
    color: #fff;
  }
  .signout-bt {
    text-align: center;
    color: ${blueAccent};
    font-weight: 600;
    text-decoration: underline;
    display: block;
    cursor: pointer;
  }
  h4 {
    text-align: center;
  }
  .linkedin {
    display: flex;
    justify-content: center;
    div {
      background: #1376b5;
      max-width: 221px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

      button {
        padding: 8px 16px;
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }
  }
`;
const Auth = ({ userInfo, onCancel }) => {
  const onSignOut = () => {
    signOut();
  };
  const authConfig = getConfig(userInfo);
  const signInToLinkedin = () => {
    const popUp = window.open(
      "popup.html",
      "linkedin-popup",
      "height=585,width=400"
    );
    window.addEventListener("message", async (event) => {
      if (event?.data?.token) {
        console.log("token here", event?.data);
        const info = await signInWithCustomToken(event?.data?.token);
        console.log({ info });
      }
    });
  };
  return (
    <Wrapper>
      <Box>
        <IconButton
          className={"close-bt"}
          aria-label="delete"
          color="primary"
          onClick={onCancel}
        >
          <CloseIcon />
        </IconButton>
        {userInfo && !userInfo.isAnonymous ? (
          <div>
            <h2>Signed is as:</h2>
            <Avatar alt={userInfo.displayName} src={userInfo.photoURL} />
            <h4>{userInfo.displayName}</h4>
            <a className="signout-bt" onClick={onSignOut}>
              Sign out
            </a>
          </div>
        ) : (
          <div>
            <h2>Sign in:</h2>
            <StyledFirebaseAuth
              firebaseAuth={authConfig.auth}
              uiConfig={authConfig.uiConfig}
            />
            <div className="linkedin">
              <div>
                <button onClick={signInToLinkedin}>
                  <img src={getPublicUrl("/linkedIn-button.png")} />
                </button>
              </div>
            </div>
          </div>
        )}
      </Box>
    </Wrapper>
  );
};
export default Auth;
