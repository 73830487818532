import React, { useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import styled from "styled-components";
const OptionsControl = styled(FormControl)`
  margin: 1rem;
`;
const RadioOptions = ({
  id,
  items,
  setValue,
  values,
  title,
  subTitle,
  setInvalidValues,
  setShowErrors,
}) => {
  useEffect(() => {
    setShowErrors(true);
  }, []);
  useEffect(() => {
    setInvalidValues(!values.hasOwnProperty(id) ? [id] : []);
  }, [values]);
  return (
    <OptionsControl component="fieldset">
      <FormLabel component="legend">{title}</FormLabel>
      <RadioGroup aria-label="gender" name="gender1" value={"Test Value"}>
        {items.map(({ label, value, disabled }, index) => (
          <FormControlLabel
            key={index}
            control={
              <Radio
                checked={values[id] === value}
                name={id}
                onChange={() =>
                  setValue(id, values[id] !== value ? value : undefined)
                }
                disabled={disabled}
              />
            }
            label={label}
          />
        ))}
      </RadioGroup>

      <FormHelperText>{subTitle}</FormHelperText>
    </OptionsControl>
  );
};
export default RadioOptions;
