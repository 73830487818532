import { jsPDF } from "jspdf";
import config from "./config";
import "./fonts/Raleway-normal";
import "./fonts/Raleway-bold";
const create = () => {
  const doc = new jsPDF({ unit: "in", lineHeight: 1.2, format: [8.5, 11] });
  doc.setFont("Raleway", "normal");
  //   doc.setFontType("normal");
  doc.setFontSize(config.fontSize);
  console.log("here 2");
  return doc;
};
export default create;
