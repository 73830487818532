import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { blueAccent, whiteColor } from "../../../../globals/colors";
import sleepAsync from "../../../../utilities/sleepAsync";
import ActionButton from "../../ActionButton";
import getPublicUrl from "../../../../utilities/getPublicUrl";
import questionTypes from "../../questionTypes";
import { isMobile } from "../../../../services/device";
const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: ${({ flip }) => (flip ? "row-reverse" : "row")};
  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
    max-width: 100%;
    overflow-x: auto;
    .body {
      flex-grow: 1;
    }
  }
  h1 {
    font-size: 1.7rem;
    padding: 1rem;
    font-weight: 500;
    margin: 0;
  }
  p {
    padding: 0 1rem 0 0rem;
    margin: 0;
    font-size: 0.85rem;
    color: #5a5a5a;
  }
  .MuiCheckbox-colorSecondary.Mui-checked,
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${blueAccent};
  }
`;
const Illustration = styled.div`
  min-width: 40%;
  height: 100%;
  .box {
    height: 100%;
    background: ${({ bg }) => bg};
    display: flex;
    align-items: ${({ position }) => (position ? position : "center")};
    justify-content: center;
    img {
      width: 70%;
      padding: 2rem;
    }
  }
`;
const Question = ({
  values,
  setValues,
  next,
  previous,
  media,
  flip,
  skipable,
  title,
  description,
  labels,
  form,
  readOnly,
}) => {
  const [showErrors, setShowErrors] = useState(false);
  const [invalidValues, setInvalidValues] = useState([]);
  const initialPosition = flip ? "200%" : "-100%";
  const illustrationControls = useAnimation();
  const contentControls = useAnimation();
  const runShowAnimation = async () => {
    if (media && !isMobile()) {
      await illustrationControls.start({
        left: "0%",
        transition: { duration: 0.3 },
      });
    }
    await contentControls.start({
      opacity: 1,
      transition: { duration: 0.3, type: "spring" },
    });
  };
  const proceed = async () => {
    if (invalidValues.length) {
      setShowErrors(true);
    } else {
      await runHideAnimation();
      if (next) next(values);
    }
  };
  const skip = async () => {
    await runHideAnimation();
    const validValues = Object.keys(values)
      .filter((id) => !invalidValues.includes(id))
      .reduce((acc, id) => ({ ...acc, [id]: values[id] }), {});
    if (next) next(validValues);
  };
  const runHideAnimation = async () => {
    Promise.all([
      contentControls.start({
        opacity: 0,
        transition: { duration: 0.2, type: "spring" },
      }),
      illustrationControls.start({
        left: initialPosition,
        transition: { duration: 0.2 },
      }),
    ]);
    await sleepAsync(400);
  };
  useEffect(() => {
    runShowAnimation();
  }, []);
  const getForm = () => {
    if (!form) return <div />;
    const Questions = questionTypes[form.type];

    return (
      <Questions
        {...form}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
        setInvalidValues={setInvalidValues}
        setValue={(id, value) => setValues({ ...values, [id]: value })}
        values={values}
      />
    );
  };
  return (
    <Wrapper flip={flip}>
      {media && !isMobile() && (
        <Illustration bg={media.bg} position={media.position}>
          <motion.div
            className={"box"}
            initial={{ left: initialPosition, position: "relative" }}
            animate={illustrationControls}
          >
            <img src={getPublicUrl(media.path)} />
          </motion.div>
        </Illustration>
      )}
      <motion.div
        initial={{ opacity: 0 }}
        animate={contentControls}
        className={"content"}
      >
        <div className={"body"}>
          {title && <h1>{title}</h1>}
          {description && <p>{description}</p>}
          {getForm()}
        </div>
        {!readOnly && (
          <ActionButton>
            <button
              disabled={invalidValues.length && showErrors}
              onClick={proceed}
            >
              {labels?.continue || "Continue"}
            </button>
            <button className="reverse" onClick={previous}>
              Previous
            </button>
            {skipable && (
              <button
                disabled={!invalidValues.length}
                className="reverse"
                onClick={skip}
              >
                {labels?.skip || "skip step"}
              </button>
            )}
          </ActionButton>
        )}
      </motion.div>
    </Wrapper>
  );
};
export default Question;
