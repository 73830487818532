import React, { useEffect } from "react";
import Card from "./Card";
import styled from "styled-components";
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;
const CardsSection = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
`;
const Options = ({
  id,
  items,
  setValue,
  values,
  setInvalidValues,
  setShowErrors,
}) => {
  useEffect(() => {
    // always set to tru to diable continue button on mount
    setShowErrors(true);
  }, []);
  useEffect(() => {
    if (values?.[id]) {
      setInvalidValues([]);
    } else {
      setInvalidValues([id]);
    }
  }, [values]);
  return (
    <Wrapper>
      <CardsSection>
        {items.map((item, index) => (
          <Card
            key={index}
            {...item}
            selected={item.id === values[id]}
            onSelect={() =>
              setValue(id, item.id === values[id] ? undefined : item.id)
            }
          />
        ))}
      </CardsSection>
    </Wrapper>
  );
};
export default Options;
