import React, { useRef, useState } from "react";
import domtoimage from "dom-to-image";
import styled from "styled-components";
import { whiteColor, blueAccent } from "../../globals/colors";
// import MatrixTable from "./MatrixTable";
import Bar from "./charts/Bar";
import { isMobile } from "../../services/device";
import StagesSummary from "./StagesSummary";
import { create } from "../../services/report";
import CircularProgress from "@material-ui/core/CircularProgress";
import analytics from "../../services/analytics";
const Wrapper = styled.div`
  padding: ${isMobile() ? "0.5rem" : "1rem 4rem 4rem"};
  h1,
  h2,
  h3 {
  }
  h3 {
    text-transform: uppercase;
  }
  h1 {
    font-size: ${isMobile() ? "1.7rem" : "2.5rem"};
    font-weight: 500;
    margin: 1rem;
    line-height: 2.5rem;
    span {
      font-size: 1.7rem;
    }
  }
  h2,
  h3 {
    margin: 2rem 2rem 0.5rem 2rem;
  }
  h4 {
    margin-left: 2rem;
  }
  p {
    padding: 0 2rem;
    margin: 0;
    font-size: 1rem;
    /* color: #5a5a5a; */
  }
  button {
    color: ${whiteColor};
    background-color: ${blueAccent};
    padding: 1rem 0.5rem;
    margin-top: 1rem;
    border: 0;
    font-size: 1.1rem;
    margin-left: 2rem;
    min-width: 12rem;
    cursor: pointer;
    .MuiCircularProgress-svg {
      color: #fff;
    }
  }
`;
const Details = ({ results, values, onLoginRequest, reset }) => {
  const [reportLoading, setReportLoading] = useState(false);
  const BarContainer = useRef();
  const StageContainer = useRef();
  const MatrixContainer = useRef();
  const MatrixContainerComplete = useRef();

  const barData = Object.keys(results).map((key) => ({
    name: results[key].name,
    score: results[key].score,
  }));
  const getContainerImage = async (container) => {
    const { width, height } = container.current.getBoundingClientRect();
    const aspectRatio = width / height;
    const dataUrl = await domtoimage.toPng(container.current);
    return { dataUrl, aspectRatio };
  };
  const downloadReport = async () => {
    analytics().logEvent("download_report");
    setReportLoading(true);
    const data = {
      bar: await getContainerImage(BarContainer),
      // matrix: await getContainerImage(MatrixContainer),
      // matrixComplete: await getContainerImage(MatrixContainerComplete),
      // stage: await getContainerImage(StageContainer),
    };
    await create(data, values, results);
    setReportLoading(false);
  };
  return (
    <Wrapper>
      <h1>
        <span>
          {values.company}
          <br />
        </span>
        Customer Onboarding Assessment Results &amp; Recommended Next Steps
      </h1>
      <p>
        Our assessment was designed to help you find out how your company is
        performing in each of the five stages of customer onboarding. Your
        customized report provides suggested next steps based on where your
        organization stands in terms of customer onboarding “maturity” (see next
        section) related to the foundational elements of onboarding success.
      </p>
      <Bar data={barData} container={BarContainer} />
      <h2>Get your Detailed Report</h2>
      <div>
        <button onClick={downloadReport}>
          {reportLoading ? (
            <CircularProgress size={"1rem"} />
          ) : (
            "Download Report"
          )}
        </button>
        <button onClick={onLoginRequest}>Save for later</button>
        <button onClick={reset}>Start Over</button>
      </div>
      <h2>Report Summary:</h2>
      <StagesSummary results={results} />
      {/* <div style={{ height: 0, overflow: "hidden" }}>
        <MatrixTable results={results} container={MatrixContainer} />
        <MatrixTable
          results={results}
          showCompleted
          container={MatrixContainerComplete}
        />
      </div> */}
    </Wrapper>
  );
};
export default Details;
