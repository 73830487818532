import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
let remoteApp;
export const init = () => {
  if (remoteApp) return;
  var remoteConfig = {
    apiKey: "AIzaSyA0zm8JCFKHjbGE4F9_6HsA7PpoxKI-KL8",
    authDomain: "taskray-survey.firebaseapp.com",
    databaseURL: "https://taskray-survey.firebaseio.com",
    projectId: "taskray-survey",
    storageBucket: "taskray-survey.appspot.com",
    messagingSenderId: "194697213651",
    appId: "1:194697213651:web:56bbb8f61f73900b674626",
    measurementId: "G-REKNMWHQR5",
  };
  // Initialize remote
  remoteApp = firebase.initializeApp(remoteConfig);
  firebase.analytics();
};
export const getDb = () => remoteApp.database();
export const getAuth = () => remoteApp.auth();
export const getAuthProviders = () => [
  // List of OAuth providers supported.
  firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  firebase.auth.EmailAuthProvider.PROVIDER_ID,
];
