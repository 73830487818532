export const brandColor = "#00617F";
export const blueAccent = "#008EAA";
export const purpleAccent = "#80225F";
export const greenAccent = "#228848";
export const redAccent = "#BA0C2F";
export const yellowAccent = "#F2A900";
export const orangeAccent = "#DC582A";
export const whiteColor = "#FFFFFF";
export const grayColor = "#f3f3f3";
export const fontColor = "#3e3e3e";
