import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { blueAccent, whiteColor } from "../../../globals/colors";
import sleepAsync from "../../../utilities/sleepAsync";
import getPublicUrl from "../../../utilities/getPublicUrl";
const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .content {
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
    .box {
      max-width: 70%;
    }
    text-align: center;
    button {
      color: ${whiteColor};
      background-color: ${blueAccent};
      padding: 1rem 4rem;
      margin-top: 1rem;
      border: 0;
      font-size: 1.1rem;
      cursor: pointer;
    }
  }
  h1 {
    color: ${blueAccent};
    font-size: 2.3rem;
  }
  p {
  }
`;
const Illustration = styled.div`
  flex-grow: 1;
  .box {
    background: ${({ bg }) => bg};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
      max-height: 90%;
    }
  }
`;
const HeadLine = ({ next, media }) => {
  const illustrationControls = useAnimation();
  const contentControls = useAnimation();
  const runShowAnimation = async () => {
    await illustrationControls.start({
      top: "0%",
      transition: { duration: 0.3 },
    });
    await contentControls.start({
      opacity: 1,
      transition: { duration: 0.3, type: "spring" },
    });
  };
  const runHideAnimation = async () => {
    Promise.all([
      contentControls.start({
        opacity: 0,
        transition: { duration: 0.2, type: "spring" },
      }),
      illustrationControls.start({
        top: "-110%",
        transition: { duration: 0.2 },
      }),
    ]);
    await sleepAsync(400);
    if (next) next();
  };
  useEffect(() => {
    runShowAnimation();
  }, []);
  return (
    <Wrapper>
      <Illustration bg={media.bg}>
        <motion.div
          className={"box"}
          initial={{ top: " -100%", position: "relative" }}
          animate={illustrationControls}
        >
          <img src={getPublicUrl(media.path)} />
        </motion.div>
      </Illustration>
      <div className={"content"}>
        <motion.div
          className={"box"}
          initial={{ opacity: 0 }}
          animate={contentControls}
        >
          <h1>The value of Customer onboarding</h1>
          <p>
            Pellentesque malesuada lacus vitae nisl ultricies, eu sodales urna
            ornare. Aenean vel mauris vel est egestas volutpat. Sed tristique
            diam in neque vestibulum vestibulum. Maecenas non nulla quis augue
            laoreet consectetur. Phasellus cursus vestibulum augue at semper.
          </p>
          <button onClick={runHideAnimation}>Begin Survey</button>
        </motion.div>
      </div>
    </Wrapper>
  );
};
export default HeadLine;
