import React from "react";
import GlobalHeader from "../../components/GlobalHeader";
import styled from "styled-components";
import LoadingBar from "../../components/LoadingBar";
import SurveyBox from "../../components/SurveyBox";
import Details from "./Details";
import { blueAccent } from "../../globals/colors";
import getPointsSummary from "./util/getPointsSummary";
const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  max-height: 100%;
  overflow: auto;
  padding: 2rem 0;
  min-height: 80%;
`;
const Summary = ({ onLoginRequest, values, reset }) => {
  const results = getPointsSummary(values);
  return (
    <Wrapper>
      <GlobalHeader>
        <LoadingBar progress={100} />
      </GlobalHeader>
      <Content>
        <SurveyBox scrollable>
          <Details
            results={results}
            values={values}
            onLoginRequest={onLoginRequest}
            reset={reset}
          />
        </SurveyBox>
      </Content>
    </Wrapper>
  );
};
export default Summary;
